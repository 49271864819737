import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { createStore, select, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { map, shareReplay, tap, withLatestFrom } from 'rxjs/operators';

import { UserService } from 'dist/swagger-client';
import * as _ from 'lodash';
import { MAX_PAGE_SIZE } from 'src/app/main-module/util/constant-util';
import { AuthPasswordService } from '../auth/auth-password.service';
import { Role, User } from '../models';
import { filterDefined } from '../util/rxjs-util';

interface UserInStore {
  user?: User;
}

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private store = createStore({ name: 'user' }, withProps<UserInStore>({ user: undefined }));
  public readonly isAdmin$ = this.getUser$().pipe(
    map((user) => user.roles.includes(Role.SUPERADMIN)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  public readonly isAdminOrOrgAdmin$ = this.getUser$().pipe(
    map((user) => user.roles.includes(Role.SUPERADMIN) || user.roles.includes(Role.ORGANIZATIONADMIN)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  public readonly currentOrg$ = this.getUser$().pipe(
    map((user) => user?.organization),
    filterDefined(),
  );
  public allUsersFromOrg$ = this.listAllUsersFromOrg().pipe(shareReplay({ bufferSize: 1, refCount: true }));

  constructor(
    private api: AuthPasswordService,
    private router: Router,
    private apiUserService: UserService,
  ) {}

  current(): Observable<User> {
    return this.api.me().pipe(tap({ next: (user) => this.store.update(() => ({ user })) }));
  }

  getUser$(): Observable<User> {
    return this.store.pipe(
      select((store) => store.user),
      filterDefined(),
    );
  }

  getUser(): User | undefined {
    return this.store.getValue().user;
  }

  reset(): void {
    return this.store.update(() => ({ user: undefined }));
  }

  goToIndex(state: RouterStateSnapshot | undefined): Promise<boolean> {
    this.reset();
    const queryParams = state ? { returnUrl: state.url } : undefined;
    return this.router.navigate(['login'], { queryParams });
  }

  /** Get all users depending on current user permissions */
  public listAllUsersFromOrg() {
    return this.apiUserService.userList(undefined, 1, MAX_PAGE_SIZE).pipe(
      map((response) => response.data),
      withLatestFrom(this.currentOrg$),
      map(([users, organization]) => _.filter(users, (user) => user.organization?.id === organization?.id)),
    );
  }
}
