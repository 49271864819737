import { UserDto, WhiteList } from 'swagger-client';

import { Lang } from './service/lang.service';

export const Role = UserDto.RolesEnum;
export type Role = UserDto.RolesEnum;

export type Organization = {
  readonly id: number;
  whiteList?: Array<WhiteList>;
  readonly userCount?: number;
  readonly keycloakId?: string;
  name: string;
};

export type User = {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  language?: Lang;
  avatarUrl?: string;
  roles: Array<Role>;
  organization?: Organization;
  updatedAt?: Date;
  guidedTour?: boolean;
};
export type Invitation = {
  id: string;
  host: User;
  guest: User;
  createdAt?: Date;
  acceptedAt?: Date;
  canceledAt?: Date;
  expirationDate?: Date;
  secretToken?: string;
  organization: Organization;
};
export type RegisterByInvitationForm = {
  password: string;
  confirmPassword: string;
  secretToken: string;
  acceptedTerms: boolean;
  language?: Lang;
  firstName?: string;
  lastName?: string;
};
