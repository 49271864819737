/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Constant {
  readonly id?: number;
  type: Constant.TypeEnum;
  value: string;
  readonly formattedValue?: string;
  deltaDays?: number;
  deltaMonths?: number;
  deltaYears?: number;
}
export namespace Constant {
  export type TypeEnum = 'NUM' | 'STR' | 'DATE' | 'BOOL' | 'ADDR';
  export const TypeEnum = {
    NUM: 'NUM' as TypeEnum,
    STR: 'STR' as TypeEnum,
    DATE: 'DATE' as TypeEnum,
    BOOL: 'BOOL' as TypeEnum,
    ADDR: 'ADDR' as TypeEnum,
  };
}
