/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FolderModeLean } from './folderModeLean';

export interface Folder {
  readonly id?: number;
  readonly isMultiple?: boolean;
  readonly rulesCorrect?: number;
  readonly rulesError?: number;
  readonly rulesMissing?: number;
  readonly documents?: number;
  propertyTypes?: Array<string>;
  modes?: Array<FolderModeLean>;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  name: string;
  number: string;
  category: Folder.CategoryEnum;
  dateSignature?: string;
  dateComp?: string;
  readonly archived?: boolean;
  archivedAt?: Date;
  region: Folder.RegionEnum;
  propertyType?: Folder.PropertyTypeEnum;
  lastVerification?: Date;
  collaborator?: string;
}
export namespace Folder {
  export type CategoryEnum = 'IMMO';
  export const CategoryEnum = {
    IMMO: 'IMMO' as CategoryEnum,
  };
  export type RegionEnum = 'WALLONIE' | 'FLANDRES' | 'BXL';
  export const RegionEnum = {
    WALLONIE: 'WALLONIE' as RegionEnum,
    FLANDRES: 'FLANDRES' as RegionEnum,
    BXL: 'BXL' as RegionEnum,
  };
  export type PropertyTypeEnum = 'HOUSE' | 'HOUSE_AND_DEPEDENCIES' | 'APPARTMENT' | 'TERRAIN' | 'TERRAINS';
  export const PropertyTypeEnum = {
    HOUSE: 'HOUSE' as PropertyTypeEnum,
    HOUSEANDDEPEDENCIES: 'HOUSE_AND_DEPEDENCIES' as PropertyTypeEnum,
    APPARTMENT: 'APPARTMENT' as PropertyTypeEnum,
    TERRAIN: 'TERRAIN' as PropertyTypeEnum,
    TERRAINS: 'TERRAINS' as PropertyTypeEnum,
  };
}
