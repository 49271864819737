/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ContactSupport {
  readonly id?: number;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  category: ContactSupport.CategoryEnum;
  priority: ContactSupport.PriorityEnum;
  description: string;
  user: string;
  folder?: number;
}
export namespace ContactSupport {
  export type CategoryEnum = 'OTHER' | 'SUGGESTION' | 'DOCUMENT_IMPORT' | 'RULE_VERIFICATION' | 'VISUAL';
  export const CategoryEnum = {
    OTHER: 'OTHER' as CategoryEnum,
    SUGGESTION: 'SUGGESTION' as CategoryEnum,
    DOCUMENTIMPORT: 'DOCUMENT_IMPORT' as CategoryEnum,
    RULEVERIFICATION: 'RULE_VERIFICATION' as CategoryEnum,
    VISUAL: 'VISUAL' as CategoryEnum,
  };
  export type PriorityEnum = 'LOW' | 'MEDIUM' | 'HIGH' | 'CRITICAL';
  export const PriorityEnum = {
    LOW: 'LOW' as PriorityEnum,
    MEDIUM: 'MEDIUM' as PriorityEnum,
    HIGH: 'HIGH' as PriorityEnum,
    CRITICAL: 'CRITICAL' as PriorityEnum,
  };
}
