/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FolderMode {
  readonly id?: number;
  mode: FolderMode.ModeEnum;
  lastVerification?: Date;
  readonly frozen?: boolean;
  readonly rulesSnapshot?: any;
  readonly documentsSnapshot?: any;
  progressSnapshot?: any;
  folder: number;
}
export namespace FolderMode {
  export type ModeEnum = 'COMPROMIS' | 'VENTE' | 'CREDIT' | 'MANDAT_HYPO' | 'MAIN_LEVEE';
  export const ModeEnum = {
    COMPROMIS: 'COMPROMIS' as ModeEnum,
    VENTE: 'VENTE' as ModeEnum,
    CREDIT: 'CREDIT' as ModeEnum,
    MANDATHYPO: 'MANDAT_HYPO' as ModeEnum,
    MAINLEVEE: 'MAIN_LEVEE' as ModeEnum,
  };
}
