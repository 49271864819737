/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Organization } from './organization';

export interface UserDto {
  readonly id: string;
  organization?: Organization;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly lastLoginAt?: Date;
  readonly email: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly roles: Array<UserDto.RolesEnum>;
  readonly keycloakId?: string;
  language?: UserDto.LanguageEnum;
  readonly avatarSmall?: string;
  readonly avatarMedium?: string;
  readonly avatarOriginal?: string;
  avatarUrl?: string;
  guidedTour?: boolean;
  roleDb?: UserDto.RoleDbEnum;
  readonly createdBy?: string;
  readonly lastUpdatedBy?: string;
}
export namespace UserDto {
  export type RolesEnum = 'SUPER_ADMIN' | 'ORGANIZATION_ADMIN' | 'USER';
  export const RolesEnum = {
    SUPERADMIN: 'SUPER_ADMIN' as RolesEnum,
    ORGANIZATIONADMIN: 'ORGANIZATION_ADMIN' as RolesEnum,
    USER: 'USER' as RolesEnum,
  };
  export type LanguageEnum = 'fr' | 'nl';
  export const LanguageEnum = {
    Fr: 'fr' as LanguageEnum,
    Nl: 'nl' as LanguageEnum,
  };
  export type RoleDbEnum = 'SUPER_ADMIN' | 'ORGANIZATION_ADMIN' | 'USER';
  export const RoleDbEnum = {
    SUPERADMIN: 'SUPER_ADMIN' as RoleDbEnum,
    ORGANIZATIONADMIN: 'ORGANIZATION_ADMIN' as RoleDbEnum,
    USER: 'USER' as RoleDbEnum,
  };
}
