@if (!accepted) {
    <div class="cookie-consent">
        <div class="cookie gap-1">
            <img alt="cookie-logo" src="assets/img/monogram.svg" width="120px" class="mb-2" />
            <p>{{ "COOKIES.MESSAGE" | translate }}</p>
            <p-button
                [title]="'COOKIES.ACCEPT_COOKIES' | translate"
                styleClass="p-button-rounded"
                type="button"
                [label]="'COOKIES.ACCEPT_COOKIES' | translate"
                (click)="accept()"
            >
            </p-button>
        </div>
    </div>
}
