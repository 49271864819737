import { Observable, OperatorFunction } from 'rxjs';
import { filter, first, shareReplay, switchMap } from 'rxjs/operators';

export function filterDefined<T>(): OperatorFunction<T | undefined | null, T> {
  return (from: Observable<T | undefined | null>) => {
    return from.pipe(filter((e): e is T => e !== undefined && e !== null));
  };
}

/**
 * @returns a shareReplay operator with bufferSize 1 and refCount true.
 */
export function shareReplayWithRefCount<T>(): OperatorFunction<T, T> {
  return (from: Observable<T>) => {
    return from.pipe(shareReplay({ bufferSize: 1, refCount: true }));
  };
}

/**
 * @returns an operator applying switchMap and first.
 */
export function switchMapFirst<T, R>(project: (value: T, index: number) => Observable<R>): OperatorFunction<T, R> {
  return (from: Observable<T>) => {
    return from.pipe(switchMap(project), first());
  };
}
