import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

import { LangService } from './shared-module';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  // Need to instantiate the LangService in at least one component.
  // This has to be done as early as possible, so translations are loaded
  constructor(private langService: LangService, private title: Title) {
    if (environment.projectName) {
      this.title.setTitle(environment.projectName);
    }
  }
}
