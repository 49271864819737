import { FormatWidth, getLocaleDateFormat, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import { PrimeNGConfig } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { BehaviorSubject, distinctUntilChanged, map, take } from 'rxjs';

import { CurrentUserService } from '../user/current-user.service';
import { filterDefined } from '../util/rxjs-util';
import { LoggerService } from './logger.service';

/** Add locales here */
registerLocaleData(localeFr);
registerLocaleData(localeEn);
registerLocaleData(localeNl);
registerLocaleData(localeDe);

// @@@NEW-PROJECT@@@: Define allowed languages
export const availableLangs = ['fr', 'nl'] as const;
export type Lang = typeof availableLangs[number];
export const defaultLang: Lang = 'fr' as const;

/**
 * This service manage the language of the app throught ngx-translate
 */
@Injectable({
  providedIn: 'root',
})
export class LangService {
  // Warning: language strings are also used as locale, so they must adhere to locale string formatting
  // For example, replacing 'en' with 'english' would result in a "missing locale data" error

  private currentLang$$: BehaviorSubject<Lang> = new BehaviorSubject<Lang>(defaultLang);
  public currentLang$ = this.currentLang$$.pipe();
  private currentLangSync: Lang = defaultLang;
  public get currentLang(): Lang {
    return this.currentLangSync;
  }

  constructor(
    private translate: TranslateService,
    private logger: LoggerService,
    private primeNg: PrimeNGConfig,
    private currentUserService: CurrentUserService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.addLangs(availableLangs as unknown as string[]);
    this.translate.setDefaultLang(defaultLang);

    this.currentUserService
      .getUser$()
      .pipe(
        map((user) => user.language),
        filterDefined(),
        distinctUntilChanged()
      )
      .subscribe((language) => this.setLang(language));

    // We use `window.location.search` to retrieve query params since ActivatedRoute does not work with non-routed stuff
    const urlLang = new URLSearchParams(window.location.search).get('lang');
    if (urlLang && this.setLang(urlLang)) return;

    const userLang = navigator.language;
    if (userLang && this.setLang(userLang)) return;

    this.setLang(defaultLang);
  }

  private isAvailableLang(lang: string): lang is Lang {
    return _.includes(availableLangs, lang);
  }

  /**
   * Update the lang of the app if the lang is supported and is not the actual one. Do nothing otherwise.
   * Return a Lang if the lang was correctly used
   */
  public setLang(lang: string | undefined): Lang | undefined {
    if (!lang) return undefined;
    const langSafe = _.toLower(_.head(lang.split('-')));
    if (langSafe && this.isAvailableLang(langSafe)) {
      if (this.translate.currentLang !== langSafe) {
        this.translate
          .use(langSafe)
          .pipe(take(1))
          .subscribe(() => {
            this.currentLang$$.next(langSafe);
            document.documentElement.lang = langSafe;
            this.currentLangSync = langSafe;
            this.primeNg.setTranslation(this.translate.instant('PRIMENG'));
            // https://stackoverflow.com/questions/66884173/how-to-change-primeng-datatable-p-column-filter-date-format
            Calendar.prototype.getDateFormat = () =>
              getLocaleDateFormat(langSafe, FormatWidth.Short).replace('MM', 'mm').replace('M', 'm');
          });

        this.logger.info(`Language set to ${langSafe}.`);
      }
      return langSafe;
    } else {
      this.logger.error(`Language ${langSafe} is not supported.`);
      return undefined;
    }
  }
}
