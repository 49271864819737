/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Document } from '../model/document';
import { DocumentSerializerNested } from '../model/documentSerializerNested';
import { InlineResponse2002 } from '../model/inlineResponse2002';
import { TextAndIndices } from '../model/textAndIndices';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class DocumentsService {
  protected basePath = 'http://localhost:9000/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Document model viewset. Only documents in the user organization are visible. The serializer for listing documents or retrieving a specific document deserializes the folder (see DocumentSerializerNested).
   * @param body
   * @param mode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public documentsCreate(
    body: Document,
    mode: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Document>;
  public documentsCreate(
    body: Document,
    mode: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Document>>;
  public documentsCreate(
    body: Document,
    mode: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Document>>;
  public documentsCreate(
    body: Document,
    mode: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling documentsCreate.');
    }

    if (mode === null || mode === undefined) {
      throw new Error('Required parameter mode was null or undefined when calling documentsCreate.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (mode !== undefined && mode !== null) {
      queryParameters = queryParameters.set('mode', <any>mode);
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Document>('post', `${this.basePath}/documents/`, {
      body: body,
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Overrides the destroy method to update the status of the rules linked to the document.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public documentsDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public documentsDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public documentsDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public documentsDelete(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling documentsDelete.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('delete', `${this.basePath}/documents/${encodeURIComponent(String(id))}/`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Find text from a set of spatial coordinates.
   * @param id
   * @param page
   * @param x
   * @param y
   * @param width
   * @param height
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public documentsFindText(
    id: string,
    page: number,
    x: number,
    y: number,
    width: number,
    height: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<TextAndIndices>;
  public documentsFindText(
    id: string,
    page: number,
    x: number,
    y: number,
    width: number,
    height: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<TextAndIndices>>;
  public documentsFindText(
    id: string,
    page: number,
    x: number,
    y: number,
    width: number,
    height: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<TextAndIndices>>;
  public documentsFindText(
    id: string,
    page: number,
    x: number,
    y: number,
    width: number,
    height: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling documentsFindText.');
    }

    if (page === null || page === undefined) {
      throw new Error('Required parameter page was null or undefined when calling documentsFindText.');
    }

    if (x === null || x === undefined) {
      throw new Error('Required parameter x was null or undefined when calling documentsFindText.');
    }

    if (y === null || y === undefined) {
      throw new Error('Required parameter y was null or undefined when calling documentsFindText.');
    }

    if (width === null || width === undefined) {
      throw new Error('Required parameter width was null or undefined when calling documentsFindText.');
    }

    if (height === null || height === undefined) {
      throw new Error('Required parameter height was null or undefined when calling documentsFindText.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (x !== undefined && x !== null) {
      queryParameters = queryParameters.set('x', <any>x);
    }
    if (y !== undefined && y !== null) {
      queryParameters = queryParameters.set('y', <any>y);
    }
    if (width !== undefined && width !== null) {
      queryParameters = queryParameters.set('width', <any>width);
    }
    if (height !== undefined && height !== null) {
      queryParameters = queryParameters.set('height', <any>height);
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<TextAndIndices>(
      'get',
      `${this.basePath}/documents/${encodeURIComponent(String(id))}/find_text/`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * Document model viewset. Only documents in the user organization are visible. The serializer for listing documents or retrieving a specific document deserializes the folder (see DocumentSerializerNested).
   * @param folder Filter documents by folder
   * @param page Offset for Pagination
   * @param page_size Page Size
   * @param mode Optionally filters per mode (type is categorical)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public documentsList(
    folder: number,
    page?: number,
    page_size?: number,
    mode?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<InlineResponse2002>;
  public documentsList(
    folder: number,
    page?: number,
    page_size?: number,
    mode?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<InlineResponse2002>>;
  public documentsList(
    folder: number,
    page?: number,
    page_size?: number,
    mode?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<InlineResponse2002>>;
  public documentsList(
    folder: number,
    page?: number,
    page_size?: number,
    mode?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (folder === null || folder === undefined) {
      throw new Error('Required parameter folder was null or undefined when calling documentsList.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (folder !== undefined && folder !== null) {
      queryParameters = queryParameters.set('folder', <any>folder);
    }
    if (mode !== undefined && mode !== null) {
      queryParameters = queryParameters.set('mode', <any>mode);
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse2002>('get', `${this.basePath}/documents/`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Overrides the start and end index when the value is changed in a PATCH request.
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public documentsPartialUpdate(
    body: Document,
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Document>;
  public documentsPartialUpdate(
    body: Document,
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Document>>;
  public documentsPartialUpdate(
    body: Document,
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Document>>;
  public documentsPartialUpdate(
    body: Document,
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling documentsPartialUpdate.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling documentsPartialUpdate.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Document>('patch', `${this.basePath}/documents/${encodeURIComponent(String(id))}/`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Document model viewset. Only documents in the user organization are visible. The serializer for listing documents or retrieving a specific document deserializes the folder (see DocumentSerializerNested).
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public documentsRead(id: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSerializerNested>;
  public documentsRead(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DocumentSerializerNested>>;
  public documentsRead(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DocumentSerializerNested>>;
  public documentsRead(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling documentsRead.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DocumentSerializerNested>(
      'get',
      `${this.basePath}/documents/${encodeURIComponent(String(id))}/`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * Return the snapshot of the documents (for frozen folder modes).
   * @param folder Filter documents by folder
   * @param frozen_mode Specifies the snapshot
   * @param page A page number within the paginated result set.
   * @param page_size Number of results to return per page.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public documentsSnapshot(
    folder: number,
    frozen_mode: string,
    page?: number,
    page_size?: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<DocumentSerializerNested>>;
  public documentsSnapshot(
    folder: number,
    frozen_mode: string,
    page?: number,
    page_size?: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<DocumentSerializerNested>>>;
  public documentsSnapshot(
    folder: number,
    frozen_mode: string,
    page?: number,
    page_size?: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<DocumentSerializerNested>>>;
  public documentsSnapshot(
    folder: number,
    frozen_mode: string,
    page?: number,
    page_size?: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (folder === null || folder === undefined) {
      throw new Error('Required parameter folder was null or undefined when calling documentsSnapshot.');
    }

    if (frozen_mode === null || frozen_mode === undefined) {
      throw new Error('Required parameter frozen_mode was null or undefined when calling documentsSnapshot.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (folder !== undefined && folder !== null) {
      queryParameters = queryParameters.set('folder', <any>folder);
    }
    if (frozen_mode !== undefined && frozen_mode !== null) {
      queryParameters = queryParameters.set('frozen_mode', <any>frozen_mode);
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<DocumentSerializerNested>>('get', `${this.basePath}/documents/snapshot/`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Document model viewset. Only documents in the user organization are visible. The serializer for listing documents or retrieving a specific document deserializes the folder (see DocumentSerializerNested).
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public documentsUpdate(body: Document, id: string, observe?: 'body', reportProgress?: boolean): Observable<Document>;
  public documentsUpdate(
    body: Document,
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Document>>;
  public documentsUpdate(
    body: Document,
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Document>>;
  public documentsUpdate(
    body: Document,
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling documentsUpdate.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling documentsUpdate.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Document>('put', `${this.basePath}/documents/${encodeURIComponent(String(id))}/`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
