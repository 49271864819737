/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Prediction {
  readonly id?: number;
  doc: number;
  _var: number;
  readonly entityName?: string;
  value?: string;
  group: string;
  formattedValue?: string;
  score?: number;
  startIndex?: number;
  endIndex?: number;
  coordinates?: any;
  main?: boolean;
  userCreated?: boolean;
  status?: Prediction.StatusEnum;
  entity?: string;
  parent?: number;
}
export namespace Prediction {
  export type StatusEnum = 'PENDING' | 'CORRECT' | 'INCORRECT';
  export const StatusEnum = {
    PENDING: 'PENDING' as StatusEnum,
    CORRECT: 'CORRECT' as StatusEnum,
    INCORRECT: 'INCORRECT' as StatusEnum,
  };
}
