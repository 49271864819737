import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api/api.service';
import { User } from '../models';
import { Authenticable } from './authenticable';

@Injectable({
  providedIn: 'root',
})
export class AuthPasswordService implements Authenticable<User> {
  constructor(private api: ApiService) {}

  logout(): Observable<void> {
    return this.api.logout();
  }
  me(): Observable<User> {
    return this.api.me();
  }
}
