import { Component, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

enum ModalChoice {
  Cancel,
  Redirect,
}
@Component({
  selector: 'app-redirect-confirm-component',
  template: `
    <div>
      <button
        [title]="'BUTTON.CANCEL_REDIRECTION' | translate"
        pButton
        pRipple
        type="button"
        color="primary"
        type="primary"
        class="p-button-outlined mr-2"
        (click)="onCancel()"
      >
        {{ 'BUTTON.CANCEL_REDIRECTION' | translate }}
      </button>

      <p-button
        [title]="'BUTTON.LEAVE_AND_LOSE_CHANGES' | translate"
        styleClass="p-button-sm"
        class="raised-button
        p-button-warning"
        type="button"
        (onClick)="onRedirect()"
      >
        {{ 'BUTTON.LEAVE_AND_LOSE_CHANGES' | translate }}
      </p-button>
    </div>
  `,
})
export class RedirectConfirmComponent {
  constructor(public ref: DynamicDialogRef) {}

  onRedirect() {
    this.ref.close(ModalChoice.Redirect);
  }
  onCancel() {
    this.ref.close(ModalChoice.Cancel);
  }
}

/** If the user goes to another website/closes the tab, the browser will let us run one last synchronuous function (canDeactivate)
 * to determine whether or not it should display the (non-customisable) "Leave website" popup. After canDeactivate, the execution of the
 * webapp is frozen and there is no opportunity to run any callback if the user decides to leave the website.
 * However, if the user is being redirected within the webapp, we are free to do whatever we want. Therefore, this is what this guard does
 * when the user is being redirected within the webapp:
 * if canDeactivate is true, the guard won't do anything
 * if canDeactivateAsync is implemented, we await it and if it returns true, the guard won't do anything
 * else, show the RedirectConfirmComponent and prevent the redirection or proceed with it depending on the user's choice
 */
interface ComponentCanDeactivate {
  canDeactivate: () => boolean;
  /** ⚠️⚠️ will only be ran for within-the-webapp redirection if canDeactivate returns false ⚠️⚠️ */
  canDeactivateAsync?: () => Promise<boolean>;
}

@Injectable()
export class PendingChangesGuard {
  constructor(
    private dialogService: DialogService,
    private translate: TranslateService,
  ) {}
  async canDeactivate(component: ComponentCanDeactivate): Promise<boolean> {
    if (component.canDeactivate() && (!component.canDeactivateAsync || (await component.canDeactivateAsync()))) {
      return true;
    }

    // You can pass data to the component using the data field. However, the component won't
    // receive the data in its @Input fields but through a service (DynamicDialogConfig) it needs
    // to import so we can't use PrimeNg components (without at least a wrapper) 😢
    // => We create a confirm dialog through our own custom component
    const ref = this.dialogService.open(RedirectConfirmComponent, {
      header: this.translate.instant('GENERIC.UNSAVED_CHANGES'),
    });
    return firstValueFrom(ref.onClose.pipe(map((c) => c === ModalChoice.Redirect)));
  }
}
