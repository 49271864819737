/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotifService } from '../service/notif.service';

interface CustomError {
  args: unknown;
  code: number;
  id: string;
  type: string;
  detail?: string; // For Django
}

/**
 * When a API call is in error (400 or higher), we display a notification error with the error message
 * (or the error code if no message)
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private notif: NotifService, private translate: TranslateService) {}

  private extract(err: any): any {
    return err?.error?.message || err?.error?.error || err?.error?.detail || err?.message || err?.error || err;
  }

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status >= 400) {
          if (err.error?.args && err.error.type) {
            const customError: CustomError = err.error;
            this.notif.error(customError.type + ' - ' + customError.code);
          } else {
            // Since error message can vary from one api to another, we try aggressively to get a message for the notification
            const message = this.extract(err);
            if (typeof message === 'string' && message) {
              const maybeTranslation = this.translate.instant(message);
              if (maybeTranslation !== message) this.notif.error(maybeTranslation);
              // Detect if the error is a translation key
              else this.notif.error(message);
            } else {
              // No message found
              this.notif.error('' + err.status);
            }
          }
        }
        return throwError(() => err);
      })
    );
  }
}
