<form [formGroup]="formGroup">
    @if (availableLangs$ | async; as availableLangs) {
        <p-dropdown
            class="p-inputtext-sm"
            [options]="availableLangs"
            formControlName="lang"
            optionLabel="text"
            optionValue="value"
        ></p-dropdown>
    }
</form>
