export const environment = {
  projectName: 'CheckR',
  logo: 'assets/img/b12-logo-square.svg',
  showLegalFooter: true,
  production: true,
  test: false,
  BASE_PATH: 'https://www.check-r.be',
  KEYCLOAK_BASE_PATH: 'https://www.check-r.be/auth',
  API_BASE_PATH: 'https://www.check-r.be/api',
  DJANGO_BASE_PATH: '',
  DJANGO_ADMIN_PANEL: '/admin-panel',
  EMAIL_DOMAIN: 'www.check-r.be',
  envName: 'production',
};
