import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ActionsService } from './api/actions.service';
import { AuthService } from './api/auth.service';
import { AvatarService } from './api/avatar.service';
import { ConstantsService } from './api/constants.service';
import { ContactsupportService } from './api/contactsupport.service';
import { DocumentsService } from './api/documents.service';
import { FoldermodesService } from './api/foldermodes.service';
import { FoldersService } from './api/folders.service';
import { InvitationService } from './api/invitation.service';
import { OrganizationsService } from './api/organizations.service';
import { PredictionsService } from './api/predictions.service';
import { RulesService } from './api/rules.service';
import { RulesoffolderService } from './api/rulesoffolder.service';
import { RulestatusService } from './api/rulestatus.service';
import { StatuslinesService } from './api/statuslines.service';
import { TagsService } from './api/tags.service';
import { UploadFromEmailService } from './api/uploadFromEmail.service';
import { UserService } from './api/user.service';
import { VariablesService } from './api/variables.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    ActionsService,
    AuthService,
    AvatarService,
    ConstantsService,
    ContactsupportService,
    DocumentsService,
    FoldermodesService,
    FoldersService,
    InvitationService,
    OrganizationsService,
    PredictionsService,
    RulesService,
    RulesoffolderService,
    RulestatusService,
    StatuslinesService,
    TagsService,
    UploadFromEmailService,
    UserService,
    VariablesService,
  ],
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}
