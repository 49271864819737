/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MyStatusLine } from './myStatusLine';

/**
 * Find the status in the same folder.
 */
export interface Status {
  readonly id?: number;
  status?: Status.StatusEnum;
  readonly lines?: Array<MyStatusLine>;
  fetched?: boolean;
  popupStatus?: Status.PopupStatusEnum;
}
export namespace Status {
  export type StatusEnum = 'MISSING' | 'PENDING' | 'CORRECT' | 'INCORRECT' | 'SLEEPING' | 'PARTIAL';
  export const StatusEnum = {
    MISSING: 'MISSING' as StatusEnum,
    PENDING: 'PENDING' as StatusEnum,
    CORRECT: 'CORRECT' as StatusEnum,
    INCORRECT: 'INCORRECT' as StatusEnum,
    SLEEPING: 'SLEEPING' as StatusEnum,
    PARTIAL: 'PARTIAL' as StatusEnum,
  };
  export type PopupStatusEnum = 'PENDING' | 'MANAGED' | 'NOT_MANAGED';
  export const PopupStatusEnum = {
    PENDING: 'PENDING' as PopupStatusEnum,
    MANAGED: 'MANAGED' as PopupStatusEnum,
    NOTMANAGED: 'NOT_MANAGED' as PopupStatusEnum,
  };
}
