import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { AuthPasswordService, LoggerService } from 'src/app/shared-module';
import { environment } from 'src/environments/environment';

/**
 * This [[Component]] is used to log the user out.
 *
 * At initialization, it calls the `logout` method and upon completion, redirects the user to "/login".
 */
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
  constructor(private authPassword: AuthPasswordService, private logger: LoggerService) {}

  ngOnInit(): void {
    if (environment.test) return; // Manipulating `window.location.href` is not very well appreciated by `ng test`

    this.authPassword
      .logout()
      .pipe(take(1))
      .subscribe(
        /*
          window.location.href completly reloads the app.
          We could instead redirect to /login but in this case we will have to reset the app state of all stores and services.
          Reloading the page trought window location ensure everything is cleaned for next session.
        */
        {
          next: () => (window.location.href = '/login'),
          error: (error) => this.logger.error(error), // [review][todo] better error handling will be needed
        }
      );
  }
}
