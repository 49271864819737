/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RuleStatus {
  readonly id?: number;
  status?: RuleStatus.StatusEnum;
  overriden?: boolean;
  fetched?: boolean;
  score?: number;
  readonly rule?: number;
  readonly folder?: number;
}
export namespace RuleStatus {
  export type StatusEnum = 'MISSING' | 'PENDING' | 'CORRECT' | 'INCORRECT' | 'SLEEPING' | 'PARTIAL';
  export const StatusEnum = {
    MISSING: 'MISSING' as StatusEnum,
    PENDING: 'PENDING' as StatusEnum,
    CORRECT: 'CORRECT' as StatusEnum,
    INCORRECT: 'INCORRECT' as StatusEnum,
    SLEEPING: 'SLEEPING' as StatusEnum,
    PARTIAL: 'PARTIAL' as StatusEnum,
  };
}
