/* eslint-disable @typescript-eslint/no-explicit-any */
/* tslint:disable no-any */
/* tslint:disable only-arrow-functions */
/* tslint:disable space-before-function-paren */

import { BehaviorSubject } from 'rxjs';

type BehaviorSubjectBooleanPropKeys<T> = {
  [K in keyof T]: T[K] extends BehaviorSubject<boolean> ? K : never;
}[keyof T];
export function spin<T>(p: BehaviorSubjectBooleanPropKeys<T>): MethodDecorator {
  return function (target: unknown, key: string | symbol, descriptor: any): any {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: any[]): any {
      this[p].next(true);
      return originalMethod.apply(this, args).add(() => this[p].next(false));
    };
  };
}

// Usage (must return a Subscription)
//
// public isLoading$ = new BehaviorSubject(false);
//
// @spin<ResetPasswordComponent>('isLoading$')
// onSubmit(): Subscription {
//   return this.api
//     .resetPassword()
//     .pipe(take(1))
//     .subscribe(() => {});
// }
