/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { CreateOrganization } from '../model/createOrganization';
import { InlineResponse2005 } from '../model/inlineResponse2005';
import { Organization } from '../model/organization';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class OrganizationsService {
  protected basePath = 'http://localhost:9000/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Variable model viewset.
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public organizationsCreate(
    body: CreateOrganization,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<CreateOrganization>;
  public organizationsCreate(
    body: CreateOrganization,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<CreateOrganization>>;
  public organizationsCreate(
    body: CreateOrganization,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<CreateOrganization>>;
  public organizationsCreate(
    body: CreateOrganization,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling organizationsCreate.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<CreateOrganization>('post', `${this.basePath}/organizations/`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Variable model viewset.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public organizationsDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public organizationsDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public organizationsDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public organizationsDelete(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling organizationsDelete.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('delete', `${this.basePath}/organizations/${encodeURIComponent(String(id))}/`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Variable model viewset.
   * @param search
   * @param page Offset for Pagination
   * @param page_size Page Size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public organizationsList(
    search?: string,
    page?: number,
    page_size?: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<InlineResponse2005>;
  public organizationsList(
    search?: string,
    page?: number,
    page_size?: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<InlineResponse2005>>;
  public organizationsList(
    search?: string,
    page?: number,
    page_size?: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<InlineResponse2005>>;
  public organizationsList(
    search?: string,
    page?: number,
    page_size?: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse2005>('get', `${this.basePath}/organizations/`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Variable model viewset.
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public organizationsPartialUpdate(
    body: Organization,
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Organization>;
  public organizationsPartialUpdate(
    body: Organization,
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Organization>>;
  public organizationsPartialUpdate(
    body: Organization,
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Organization>>;
  public organizationsPartialUpdate(
    body: Organization,
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling organizationsPartialUpdate.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling organizationsPartialUpdate.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Organization>(
      'patch',
      `${this.basePath}/organizations/${encodeURIComponent(String(id))}/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * Variable model viewset.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public organizationsRead(id: string, observe?: 'body', reportProgress?: boolean): Observable<Organization>;
  public organizationsRead(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Organization>>;
  public organizationsRead(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Organization>>;
  public organizationsRead(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling organizationsRead.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Organization>(
      'get',
      `${this.basePath}/organizations/${encodeURIComponent(String(id))}/`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * Variable model viewset.
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public organizationsUpdate(
    body: CreateOrganization,
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<CreateOrganization>;
  public organizationsUpdate(
    body: CreateOrganization,
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<CreateOrganization>>;
  public organizationsUpdate(
    body: CreateOrganization,
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<CreateOrganization>>;
  public organizationsUpdate(
    body: CreateOrganization,
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling organizationsUpdate.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling organizationsUpdate.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<CreateOrganization>(
      'put',
      `${this.basePath}/organizations/${encodeURIComponent(String(id))}/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
