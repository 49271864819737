import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import { Observable, map } from 'rxjs';

import { Role } from '../models';
import { Lang, LangService, availableLangs } from './lang.service';

export interface TranslatableEnum {
  text: string;
  value: string;
}

export interface TranslatableEnumRoles {
  text: string;
  value: Role;
}

export interface TranslatableEnumLang {
  text: string;
  value: Lang;
}

export interface TranslatableEnumBoolean {
  text: string;
  value: null | true | false;
}

@Injectable({
  providedIn: 'root',
})
export class EnumService {
  public availableRoles$: Observable<TranslatableEnumRoles[]>;
  public availableLangs$: Observable<TranslatableEnumLang[]>;
  public invitationStatus$: Observable<TranslatableEnum[]>;

  public availableRolesWithEmpty$: Observable<TranslatableEnum[]>;

  public allYesNo$: Observable<TranslatableEnumBoolean[]>;

  constructor(private langService: LangService, private translate: TranslateService) {
    this.availableRoles$ = this.langService.currentLang$.pipe(
      map(() =>
        _.values(Role).map((role) => ({
          text: this.translate.instant('ROLE.' + _.toUpper(role)),
          value: role,
        }))
      )
    );
    this.availableRolesWithEmpty$ = this.availableRoles$.pipe(map((roles) => [{ text: '', value: '' }, ...roles]));

    this.availableLangs$ = this.langService.currentLang$.pipe(
      map(() =>
        _.values(availableLangs).map((lang) => ({
          text: this.translate.instant('LANG.' + _.toUpper(lang)),
          value: lang,
        }))
      )
    );

    this.invitationStatus$ = this.langService.currentLang$.pipe(
      map(() =>
        ['all', 'accepted', 'pending', 'canceled', 'expired'].map((s) => ({
          text: this.translate.instant('INVITATION_STATUS.' + _.toUpper(s)),
          value: s,
        }))
      )
    );

    this.allYesNo$ = this.langService.currentLang$.pipe(
      map(() => [
        { text: this.translate.instant('GENERIC.ALL'), value: null },
        { text: this.translate.instant('GENERIC.YES'), value: true },
        { text: this.translate.instant('GENERIC.NO'), value: false },
      ])
    );
  }
}
