/* eslint-disable no-console */
// tslint:disable: no-console

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  info(message?: unknown, ...optionalParams: unknown[]): void {
    if (environment.test) return;
    console.info(message, optionalParams);
  }
  debug(message?: unknown, ...optionalParams: unknown[]): void {
    if (environment.test) return;
    console.debug(message, optionalParams);
  }
  log(message?: unknown, ...optionalParams: unknown[]): void {
    console.log(message, optionalParams);
  }
  warn(message?: unknown, ...optionalParams: unknown[]): void {
    console.warn(message, optionalParams);
  }
  error(message?: unknown, ...optionalParams: unknown[]): void {
    console.error(message, optionalParams);
  }
}
