import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * This [[Component]] is used when a user navigates to a page that do not exist (anymore).
 *
 * This page is at least required to allow the user to go back to the homepage of the app within a simple click.
 * Template details are left to the discretion of each project.
 */
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {}
