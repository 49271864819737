import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CurrentUserService } from '../user/current-user.service';

/**
 * This class prevents a non logged in user from accessing view requiring to be connected.
 *
 * Guards are used in the routes of the router.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private currentUserService: CurrentUserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const user = this.currentUserService.getUser();
    if (!user || !user.email) return this.isLoggedIn(state);
    return true;
  }

  isLoggedIn(state: RouterStateSnapshot): Observable<boolean> {
    return this.currentUserService.current().pipe(
      map(() => true),
      catchError(() => from(this.currentUserService.goToIndex(state))),
    );
  }
}
