import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { EnumService, TranslatableEnumLang } from '../../service/enum.service';
import { Lang, LangService } from '../../service/lang.service';
import { Destroyable } from '../destroyable/destroyable.component';

/** Manage the current lang of the app */
@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LangSelectorComponent extends Destroyable {
  public readonly availableLangs$: Observable<TranslatableEnumLang[]> = this.enumService.availableLangs$;
  public readonly formGroup = this.fb.group({
    lang: this.fb.control(this.translate.currentLang),
  });

  @Output() readonly langChange: EventEmitter<Lang> = new EventEmitter<Lang>();

  constructor(
    private fb: NonNullableFormBuilder,
    private langService: LangService,
    private translate: TranslateService,
    private enumService: EnumService
  ) {
    super();
    this.translate.onLangChange
      .pipe(this.takeUntilDestroy())
      .subscribe((lang: LangChangeEvent) => this.formGroup.setValue({ lang: lang.lang }));

    this.formGroup.controls.lang.valueChanges
      .pipe(this.takeUntilDestroy(), distinctUntilChanged())
      .subscribe((lang) => {
        const safeLang = this.langService.setLang(lang);
        if (safeLang) this.langChange.next(safeLang);
      });
  }
}
