import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/shared-module';

import { LoginComponent } from './auth/component/login/login.component';
import { LogoutComponent } from './auth/component/logout/logout.component';
import { NotFoundComponent } from './auth/component/not-found/not-found.component';

const routes: Routes = [
  // Try to keep url path and component name equal. This ease code navigation and reduce amount of terms to remember.
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'not-found', component: NotFoundComponent },
  {
    path: 'app',
    // Guards are used to protect routes of the app. If we try to access a protected place, the guard will refuse the navigation.
    // Here only logged in users can access this path.
    // See: https://angular.io/guide/router#preventing-unauthorized-access
    canActivate: [AuthGuard],
    // This lazy loads the module only when accessing it (so only when we are logged in).
    // Why?
    // - Performance improvement: we only load modules we need (e.g. a simple user do not need to load the admin module...).
    // - Guarding parts of the code: sometimes the code needs to be protected only for authorised users
    //     because it could contain proprietary or sensitive parts.
    // See: https://angular.io/guide/lazy-loading-ngmodules
    loadChildren: () => import('./main-module/main.module').then((m) => m.MainModule),
  },
  // End of the routes. Angular router reaches here if it has matched nothing previously. So we redirect to our 404 route.
  { path: '', redirectTo: '/app/folders/all', pathMatch: 'full' },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
