/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Folder } from '../model/folder';
import { FolderDocumentsMetadata } from '../model/folderDocumentsMetadata';
import { FolderProgress } from '../model/folderProgress';
import { FolderSerializerPartialUpdate } from '../model/folderSerializerPartialUpdate';
import { FolderSerializerRetrieve } from '../model/folderSerializerRetrieve';
import { FolderValidation } from '../model/folderValidation';
import { FolderValidationBody } from '../model/folderValidationBody';
import { InlineResponse2004 } from '../model/inlineResponse2004';
import { SimpleFolder } from '../model/simpleFolder';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class FoldersService {
  protected basePath = 'http://localhost:9000/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Return the modes that can still be created for a folder.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public foldersAllowedModes(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
  public foldersAllowedModes(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<string>>>;
  public foldersAllowedModes(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<string>>>;
  public foldersAllowedModes(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling foldersAllowedModes.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<string>>(
      'get',
      `${this.basePath}/folders/${encodeURIComponent(String(id))}/allowed_modes/`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * Archive a specific folder.
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public foldersArchive(body: Folder, id: string, observe?: 'body', reportProgress?: boolean): Observable<Folder>;
  public foldersArchive(
    body: Folder,
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Folder>>;
  public foldersArchive(
    body: Folder,
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Folder>>;
  public foldersArchive(
    body: Folder,
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling foldersArchive.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling foldersArchive.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Folder>(
      'post',
      `${this.basePath}/folders/${encodeURIComponent(String(id))}/archive/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * Validate a specific folder.
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public foldersAreTypesValid(
    body: FolderValidationBody,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<FolderValidation>>;
  public foldersAreTypesValid(
    body: FolderValidationBody,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<FolderValidation>>>;
  public foldersAreTypesValid(
    body: FolderValidationBody,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<FolderValidation>>>;
  public foldersAreTypesValid(
    body: FolderValidationBody,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling foldersAreTypesValid.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Array<FolderValidation>>('post', `${this.basePath}/folders/are_types_valid/`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Folder model viewset. Only folders in the user organization are visible.
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public foldersCreate(body: Folder, observe?: 'body', reportProgress?: boolean): Observable<Folder>;
  public foldersCreate(body: Folder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Folder>>;
  public foldersCreate(body: Folder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Folder>>;
  public foldersCreate(body: Folder, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling foldersCreate.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Folder>('post', `${this.basePath}/folders/`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Folder model viewset. Only folders in the user organization are visible.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public foldersDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public foldersDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public foldersDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public foldersDelete(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling foldersDelete.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('delete', `${this.basePath}/folders/${encodeURIComponent(String(id))}/`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Get required and missing documents of a specific folder.
   * @param id
   * @param mode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public foldersDocumentsMetadata(
    id: string,
    mode?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<FolderDocumentsMetadata>;
  public foldersDocumentsMetadata(
    id: string,
    mode?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<FolderDocumentsMetadata>>;
  public foldersDocumentsMetadata(
    id: string,
    mode?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<FolderDocumentsMetadata>>;
  public foldersDocumentsMetadata(
    id: string,
    mode?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling foldersDocumentsMetadata.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (mode !== undefined && mode !== null) {
      queryParameters = queryParameters.set('mode', <any>mode);
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FolderDocumentsMetadata>(
      'get',
      `${this.basePath}/folders/${encodeURIComponent(String(id))}/documents_metadata/`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * Validate a specific folder.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public foldersIsValid(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FolderValidation>>;
  public foldersIsValid(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<FolderValidation>>>;
  public foldersIsValid(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<FolderValidation>>>;
  public foldersIsValid(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling foldersIsValid.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<FolderValidation>>(
      'get',
      `${this.basePath}/folders/${encodeURIComponent(String(id))}/is_valid/`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * Override list to optimize pre-fetching with pagination.
   * @param search
   * @param page Offset for Pagination
   * @param page_size Page Size
   * @param sort
   * @param column_filter
   * @param archived Archive status of the folders
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public foldersList(
    search?: string,
    page?: number,
    page_size?: number,
    sort?: string,
    column_filter?: string,
    archived?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<InlineResponse2004>;
  public foldersList(
    search?: string,
    page?: number,
    page_size?: number,
    sort?: string,
    column_filter?: string,
    archived?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<InlineResponse2004>>;
  public foldersList(
    search?: string,
    page?: number,
    page_size?: number,
    sort?: string,
    column_filter?: string,
    archived?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<InlineResponse2004>>;
  public foldersList(
    search?: string,
    page?: number,
    page_size?: number,
    sort?: string,
    column_filter?: string,
    archived?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (sort !== undefined && sort !== null) {
      queryParameters = queryParameters.set('sort', <any>sort);
    }
    if (column_filter !== undefined && column_filter !== null) {
      queryParameters = queryParameters.set('column_filter', <any>column_filter);
    }
    if (archived !== undefined && archived !== null) {
      queryParameters = queryParameters.set('archived', <any>archived);
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse2004>('get', `${this.basePath}/folders/`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Folder model viewset. Only folders in the user organization are visible.
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public foldersPartialUpdate(
    body: FolderSerializerPartialUpdate,
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<FolderSerializerPartialUpdate>;
  public foldersPartialUpdate(
    body: FolderSerializerPartialUpdate,
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<FolderSerializerPartialUpdate>>;
  public foldersPartialUpdate(
    body: FolderSerializerPartialUpdate,
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<FolderSerializerPartialUpdate>>;
  public foldersPartialUpdate(
    body: FolderSerializerPartialUpdate,
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling foldersPartialUpdate.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling foldersPartialUpdate.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<FolderSerializerPartialUpdate>(
      'patch',
      `${this.basePath}/folders/${encodeURIComponent(String(id))}/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * Get the progress of a specific folder (possibly filtered by mode).
   * @param id
   * @param mode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public foldersProgress(
    id: string,
    mode?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<FolderProgress>;
  public foldersProgress(
    id: string,
    mode?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<FolderProgress>>;
  public foldersProgress(
    id: string,
    mode?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<FolderProgress>>;
  public foldersProgress(
    id: string,
    mode?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling foldersProgress.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (mode !== undefined && mode !== null) {
      queryParameters = queryParameters.set('mode', <any>mode);
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FolderProgress>(
      'get',
      `${this.basePath}/folders/${encodeURIComponent(String(id))}/progress/`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * Folder model viewset. Only folders in the user organization are visible.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public foldersRead(id: string, observe?: 'body', reportProgress?: boolean): Observable<FolderSerializerRetrieve>;
  public foldersRead(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<FolderSerializerRetrieve>>;
  public foldersRead(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<FolderSerializerRetrieve>>;
  public foldersRead(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling foldersRead.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FolderSerializerRetrieve>(
      'get',
      `${this.basePath}/folders/${encodeURIComponent(String(id))}/`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * Get a simple list of folders with id, name, and number.
   * @param search A search term.
   * @param page A page number within the paginated result set.
   * @param page_size Number of results to return per page.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public foldersSimplifiedList(
    search?: string,
    page?: number,
    page_size?: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<SimpleFolder>>;
  public foldersSimplifiedList(
    search?: string,
    page?: number,
    page_size?: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<SimpleFolder>>>;
  public foldersSimplifiedList(
    search?: string,
    page?: number,
    page_size?: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<SimpleFolder>>>;
  public foldersSimplifiedList(
    search?: string,
    page?: number,
    page_size?: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<SimpleFolder>>('get', `${this.basePath}/folders/simplified_list/`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Folder model viewset. Only folders in the user organization are visible.
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public foldersUpdate(body: Folder, id: string, observe?: 'body', reportProgress?: boolean): Observable<Folder>;
  public foldersUpdate(
    body: Folder,
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Folder>>;
  public foldersUpdate(
    body: Folder,
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Folder>>;
  public foldersUpdate(
    body: Folder,
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling foldersUpdate.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling foldersUpdate.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Folder>('put', `${this.basePath}/folders/${encodeURIComponent(String(id))}/`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
