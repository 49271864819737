import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class NotifService {
  constructor(private messageService: MessageService) {}

  info(message: string): void {
    this.messageService.add({
      severity: 'info',
      detail: message,
      life: this.duration(message),
    });
  }

  success(message: string): void {
    this.messageService.add({
      severity: 'success',
      detail: message,
      life: this.duration(message),
    });
  }

  warn(message: string): void {
    this.messageService.add({
      severity: 'warn',
      detail: message,
      life: this.duration(message),
    });
  }

  error(message: string): void {
    return this.messageService.add({
      severity: 'error',
      detail: message,
      life: this.duration(message),
    });
  }

  // Smart duration of the notification based on message length.
  // The longer the message is the longer the notificaction will remain.
  private duration(message: string): number {
    return Math.min(Math.max(2000, message.length * 75), 10000);
  }
}
