<app-environment-banner [showBanner$]="showEnvBanner$" [showBounce]="true"> </app-environment-banner>
<div class="login-page-container">
    <p-toast position="bottom-right" class="notif-error-white-space"></p-toast>
    <app-cookie-consent></app-cookie-consent>

    <div class="lang-switcher-custom">
        <app-lang-selector></app-lang-selector>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-center flex-column gap-5">
        <div>
            <img alt="logo" src="assets/img/logo-white.svg" width="500px" height="100%" />
        </div>
        <div>
            <p-button
                [title]="'AUTHENTICATION.LOG_IN' | translate"
                id="sign_in_button"
                color="primary"
                styleClass="p-button-rounded"
                (click)="redirectToAuthorizationEndpoint()"
                type="submit"
                [disabled]="(isLoading$ | async)!"
            >
                {{ "AUTHENTICATION.LOG_IN" | translate }}
            </p-button>
            @if (isLoading$ | async) {
                <app-spinner class="mt-2"></app-spinner>
            }
        </div>
    </div>
</div>
