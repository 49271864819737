import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash-es';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CurrentUserService } from '../user/current-user.service';

/** This http interceptor redirect to /logout in case of 401 or 403 http error. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // Routes to not redirect to /logout when 401 error.
  private excludedRoutes = ['/api/authenticate/credentials'];

  constructor(private router: Router, private currentUserService: CurrentUserService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // We could use the reset() method of the CurrentUserService but,
          // since the CurrentUserService uses HttpClient (through AuthPasswordService), importing it here creates circular injection.
          this.currentUserService.reset();
          // Move to logout to clean everything properly
          if (!_.some(_.find(this.excludedRoutes, (route) => _.includes(err.url, route)))) {
            this.router.navigate(['/logout']);
          }
          return throwError(() => `${err.status} ${err.statusText}`);
        }
        return throwError(() => err);
      })
    );
  }
}
