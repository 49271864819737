// Modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AutoFocusModule } from 'primeng/autofocus';
// PrimeNg
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';

import { LegalComponent } from '../main-module/component/legal/legal.component';
import { CookieConsentComponent } from './component/cookie-consent/cookie-consent.component';
import { EnvironmentBannerComponent } from './component/environment-banner/environment-banner.component';
// Components
import { LangSelectorComponent } from './component/lang-selector/lang-selector.component';
import { MessageCarouselComponent } from './component/message-carousel/message-carousel.component';
import { NavigationHeaderComponent } from './component/navigation/navigation-header/navigation-header.component';
import { NavigationSubTabComponent } from './component/navigation/navigation-sub-tab/navigation-sub-tab.component';
import { NavigationTabComponent } from './component/navigation/navigation-tab/navigation-tab.component';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { PendingChangesGuard, RedirectConfirmComponent } from './guard/pending-changes-guard';
import { CurrencyLocalePipe } from './pipe/currency-locale.pipe';
import { DateLocalePipe } from './pipe/date-locale.pipe';
import { DecimalLocalePipe } from './pipe/decimal-locale.pipe';
import { HasPermissionPipe } from './pipe/has-permission.pipe';
import { PercentLocalePipe } from './pipe/percent-locale.pipe';
import { TranslateArrayPipe } from './pipe/translate-array.pipe';
import { ExplainDisableDirective } from './directive/explain-disable.directive';
import { MergeClassesPipe } from './pipe/merge-classes.pipe';

@NgModule({
  declarations: [
    // Pipes
    CurrencyLocalePipe,
    DateLocalePipe,
    DecimalLocalePipe,
    HasPermissionPipe,
    PercentLocalePipe,
    TranslateArrayPipe,
    MergeClassesPipe,
    // Directives
    ExplainDisableDirective,
    // Components
    LangSelectorComponent,
    LegalComponent,
    RedirectConfirmComponent,
    SpinnerComponent,
    NavigationTabComponent,
    NavigationSubTabComponent,
    NavigationHeaderComponent,
    CookieConsentComponent,
    EnvironmentBannerComponent,
    MessageCarouselComponent,
  ],
  imports: [
    // Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    NgxExtendedPdfViewerModule,
    // PrimeNg
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    ChartModule,
    CheckboxModule,
    ConfirmDialogModule,
    DropdownModule,
    FileUploadModule,
    ImageModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    MenubarModule,
    MessagesModule,
    MultiSelectModule,
    PanelMenuModule,
    PaginatorModule,
    PasswordModule,
    RippleModule,
    SidebarModule,
    SkeletonModule,
    SplitButtonModule,
    TableModule,
    TagModule,
    ToastModule,
    ToolbarModule,
    TooltipModule,
    DialogModule,
    AutoFocusModule,
    KeyFilterModule,
    ScrollPanelModule,
    ProgressSpinnerModule,
  ],
  exports: [
    // Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxExtendedPdfViewerModule,
    // Pipes
    CurrencyLocalePipe,
    DateLocalePipe,
    DecimalLocalePipe,
    HasPermissionPipe,
    PercentLocalePipe,
    TranslateArrayPipe,
    MergeClassesPipe,
    // Directives
    ExplainDisableDirective,
    // Components
    LangSelectorComponent,
    LegalComponent,
    RouterModule,
    SpinnerComponent,
    NavigationTabComponent,
    NavigationSubTabComponent,
    NavigationHeaderComponent,
    CookieConsentComponent,
    EnvironmentBannerComponent,
    MessageCarouselComponent,
    // PrimeNg
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    ChartModule,
    CheckboxModule,
    ConfirmDialogModule,
    DropdownModule,
    FileUploadModule,
    ImageModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    MenubarModule,
    MessagesModule,
    MultiSelectModule,
    PanelMenuModule,
    PaginatorModule,
    PasswordModule,
    RippleModule,
    SidebarModule,
    SkeletonModule,
    SplitButtonModule,
    TableModule,
    TagModule,
    ToastModule,
    ToolbarModule,
    TooltipModule,
    ProgressSpinnerModule,
    TabViewModule,
    DynamicDialogModule,
    OverlayPanelModule,
    DialogModule,
    AutoFocusModule,
    ContextMenuModule,
    KeyFilterModule,
    ScrollPanelModule,
  ],
  providers: [PendingChangesGuard],
})
export class SharedModule {}
