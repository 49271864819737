import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthPasswordService, EnvEnum, LangService, spin } from 'src/app/shared-module';
import { User } from 'src/app/shared-module/models';
import { environment } from 'src/environments/environment';

/**
 * This component is displayed when a user first arrive on the website.
 *
 * Its job is
 * - to allow the user to fill the login form to connect
 * - to automatically redirect the user to the `returnUrl` if they are connected already
 * - to allow the user to be redirected to the [[ForgotPassword]] component.
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  public showEnvBanner$ = of(environment.envName !== EnvEnum.PRODUCTION);

  /**
   * The BehaviorSubject is used to display the spinning wheel and disable the login button
   * This could be a simple boolean but we need the async pipe.
   * Indeed, the async pipe will trigger the change detection when a new value is received.
   */
  public readonly isLoading$ = new BehaviorSubject(false);

  /**
   * The return url is the url to redirect after a successful login.
   * Typically, an unlogged user will try to access a protected page but will be caught by the AuthGuard and
   * redirected to the login page.
   * However, after his login, the user would like to access the page he previously try to reach.
   */
  public readonly returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/app';

  public readonly projectName = environment.projectName;
  public readonly logo = environment.logo;
  public readonly showLegalFooter = environment.showLegalFooter;

  constructor(
    private authPassword: AuthPasswordService,
    private router: Router,
    private route: ActivatedRoute,
    private lang: LangService
  ) {}

  ngOnInit(): void {
    this.checkAlreadyLogged();
  }

  /** Check if the user is already logged */
  @spin<LoginComponent>('isLoading$')
  checkAlreadyLogged(): Subscription {
    return this.authPassword
      .me()
      .pipe(take(1))
      .subscribe({
        next: (user: User) => this.afterLogin(user),
        error: () => {}, // Fail if user not logged.
      });
  }

  /**
   * Set the user-preference language and redirect the user to the `returnUrl` path.
   *
   * This is called after a login succeeds, either because it was already logged in, or because it filled the form.
   */
  afterLogin(user: User): void {
    if (user) {
      this.lang.setLang(user.language);
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  public redirectToAuthorizationEndpoint() {
    window.location.href = environment.DJANGO_BASE_PATH + '/api/keycloak/login';
  }
}
