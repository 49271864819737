/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { InlineResponse2008 } from '../model/inlineResponse2008';
import { RuleOfFolder } from '../model/ruleOfFolder';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class RulesoffolderService {
  protected basePath = 'http://localhost:9000/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * View with a single GET method that returns the rules of a specific folder, their status, variables and/or constants and associated predictions in a single json structure. Might be better to not use a ModelSet for this.
   * @param folder The folder of which to fetch the rules and their status.
   * @param page Offset for Pagination
   * @param page_size Page Size
   * @param mode The mode for which to fetch the rules.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rulesoffolderList(
    folder: string,
    page?: number,
    page_size?: number,
    mode?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<InlineResponse2008>;
  public rulesoffolderList(
    folder: string,
    page?: number,
    page_size?: number,
    mode?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<InlineResponse2008>>;
  public rulesoffolderList(
    folder: string,
    page?: number,
    page_size?: number,
    mode?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<InlineResponse2008>>;
  public rulesoffolderList(
    folder: string,
    page?: number,
    page_size?: number,
    mode?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (folder === null || folder === undefined) {
      throw new Error('Required parameter folder was null or undefined when calling rulesoffolderList.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (folder !== undefined && folder !== null) {
      queryParameters = queryParameters.set('folder', <any>folder);
    }
    if (mode !== undefined && mode !== null) {
      queryParameters = queryParameters.set('mode', <any>mode);
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse2008>('get', `${this.basePath}/rulesoffolder/`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * View with a single GET method that returns the rules of a specific folder, their status, variables and/or constants and associated predictions in a single json structure. Might be better to not use a ModelSet for this.
   * @param id
   * @param folder The folder of which to fetch the rules and their status.
   * @param mode The mode for which to fetch the rule.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rulesoffolderRead(
    id: string,
    folder: string,
    mode?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<RuleOfFolder>;
  public rulesoffolderRead(
    id: string,
    folder: string,
    mode?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<RuleOfFolder>>;
  public rulesoffolderRead(
    id: string,
    folder: string,
    mode?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<RuleOfFolder>>;
  public rulesoffolderRead(
    id: string,
    folder: string,
    mode?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling rulesoffolderRead.');
    }

    if (folder === null || folder === undefined) {
      throw new Error('Required parameter folder was null or undefined when calling rulesoffolderRead.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (folder !== undefined && folder !== null) {
      queryParameters = queryParameters.set('folder', <any>folder);
    }
    if (mode !== undefined && mode !== null) {
      queryParameters = queryParameters.set('mode', <any>mode);
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<RuleOfFolder>(
      'get',
      `${this.basePath}/rulesoffolder/${encodeURIComponent(String(id))}/`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * Return the snapshot of the rules (for frozen folder modes).
   * @param folder The folder of which to fetch the rules and their status.
   * @param frozen_mode The frozen mode for which to fetch snapshot.
   * @param page Offset for Pagination
   * @param page_size Page Size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rulesoffolderSnapshot(
    folder: string,
    frozen_mode: string,
    page?: number,
    page_size?: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<RuleOfFolder>>;
  public rulesoffolderSnapshot(
    folder: string,
    frozen_mode: string,
    page?: number,
    page_size?: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<RuleOfFolder>>>;
  public rulesoffolderSnapshot(
    folder: string,
    frozen_mode: string,
    page?: number,
    page_size?: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<RuleOfFolder>>>;
  public rulesoffolderSnapshot(
    folder: string,
    frozen_mode: string,
    page?: number,
    page_size?: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (folder === null || folder === undefined) {
      throw new Error('Required parameter folder was null or undefined when calling rulesoffolderSnapshot.');
    }

    if (frozen_mode === null || frozen_mode === undefined) {
      throw new Error('Required parameter frozen_mode was null or undefined when calling rulesoffolderSnapshot.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (folder !== undefined && folder !== null) {
      queryParameters = queryParameters.set('folder', <any>folder);
    }
    if (frozen_mode !== undefined && frozen_mode !== null) {
      queryParameters = queryParameters.set('frozen_mode', <any>frozen_mode);
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<RuleOfFolder>>('get', `${this.basePath}/rulesoffolder/snapshot/`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
