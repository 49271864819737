/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Document {
  readonly id?: number;
  type?: Document.TypeEnum;
  folder: number;
  readonly file?: string;
  readonly filename?: string;
  readonly createdAt?: Date;
  readonly fileParsed?: string;
  readonly filenameParsed?: string;
  lang?: Document.LangEnum;
  /**
   * Return the file name as a spearate field.
   */
  readonly modes?: Array<Document.ModesEnum>;
}
export namespace Document {
  export type TypeEnum =
    | 'COMP'
    | 'ACTE'
    | 'TITRE'
    | 'EHYP'
    | 'CAD'
    | 'CAD-REDUIT'
    | 'RU'
    | 'PEB'
    | 'DECOM'
    | 'CI'
    | 'REG'
    | 'ELEC'
    | 'BDES'
    | 'MAIN'
    | 'GARAN'
    | 'VENTE'
    | 'DEPOT'
    | 'BAIL'
    | 'CERTI'
    | 'OVAM'
    | 'CRED'
    | 'ACTE_CRED'
    | 'EH_COMPL'
    | 'EH_DEMANDE'
    | 'OTHER'
    | 'IBGE'
    | 'ARTICLE90'
    | 'COMP_SIGNE'
    | 'ACTE_MAIN'
    | 'OFFRE';
  export const TypeEnum = {
    COMP: 'COMP' as TypeEnum,
    ACTE: 'ACTE' as TypeEnum,
    TITRE: 'TITRE' as TypeEnum,
    EHYP: 'EHYP' as TypeEnum,
    CAD: 'CAD' as TypeEnum,
    CADREDUIT: 'CAD-REDUIT' as TypeEnum,
    RU: 'RU' as TypeEnum,
    PEB: 'PEB' as TypeEnum,
    DECOM: 'DECOM' as TypeEnum,
    CI: 'CI' as TypeEnum,
    REG: 'REG' as TypeEnum,
    ELEC: 'ELEC' as TypeEnum,
    BDES: 'BDES' as TypeEnum,
    MAIN: 'MAIN' as TypeEnum,
    GARAN: 'GARAN' as TypeEnum,
    VENTE: 'VENTE' as TypeEnum,
    DEPOT: 'DEPOT' as TypeEnum,
    BAIL: 'BAIL' as TypeEnum,
    CERTI: 'CERTI' as TypeEnum,
    OVAM: 'OVAM' as TypeEnum,
    CRED: 'CRED' as TypeEnum,
    ACTECRED: 'ACTE_CRED' as TypeEnum,
    EHCOMPL: 'EH_COMPL' as TypeEnum,
    EHDEMANDE: 'EH_DEMANDE' as TypeEnum,
    OTHER: 'OTHER' as TypeEnum,
    IBGE: 'IBGE' as TypeEnum,
    ARTICLE90: 'ARTICLE90' as TypeEnum,
    COMPSIGNE: 'COMP_SIGNE' as TypeEnum,
    ACTEMAIN: 'ACTE_MAIN' as TypeEnum,
    OFFRE: 'OFFRE' as TypeEnum,
  };
  export type LangEnum = 'fr' | 'nl';
  export const LangEnum = {
    Fr: 'fr' as LangEnum,
    Nl: 'nl' as LangEnum,
  };
  export type ModesEnum = 'COMPROMIS' | 'VENTE' | 'CREDIT' | 'MANDAT_HYPO' | 'MAIN_LEVEE';
  export const ModesEnum = {
    COMPROMIS: 'COMPROMIS' as ModesEnum,
    VENTE: 'VENTE' as ModesEnum,
    CREDIT: 'CREDIT' as ModesEnum,
    MANDATHYPO: 'MANDAT_HYPO' as ModesEnum,
    MAINLEVEE: 'MAIN_LEVEE' as ModesEnum,
  };
}
