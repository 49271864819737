/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WhiteList {
  readonly id?: number;
  domain: string;
}
