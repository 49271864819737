/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { InlineResponse20010 } from '../model/inlineResponse20010';
import { StatusLine } from '../model/statusLine';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class StatuslinesService {
  protected basePath = 'http://localhost:9000/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * StatusLine model viewset.
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public statuslinesCreate(body: StatusLine, observe?: 'body', reportProgress?: boolean): Observable<StatusLine>;
  public statuslinesCreate(
    body: StatusLine,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<StatusLine>>;
  public statuslinesCreate(
    body: StatusLine,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<StatusLine>>;
  public statuslinesCreate(body: StatusLine, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling statuslinesCreate.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<StatusLine>('post', `${this.basePath}/statuslines/`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * StatusLine model viewset.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public statuslinesDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public statuslinesDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public statuslinesDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public statuslinesDelete(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling statuslinesDelete.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('delete', `${this.basePath}/statuslines/${encodeURIComponent(String(id))}/`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * StatusLine model viewset.
   * @param page Offset for Pagination
   * @param page_size Page Size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public statuslinesList(
    page?: number,
    page_size?: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<InlineResponse20010>;
  public statuslinesList(
    page?: number,
    page_size?: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<InlineResponse20010>>;
  public statuslinesList(
    page?: number,
    page_size?: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<InlineResponse20010>>;
  public statuslinesList(
    page?: number,
    page_size?: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20010>('get', `${this.basePath}/statuslines/`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * StatusLine model viewset.
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public statuslinesPartialUpdate(
    body: StatusLine,
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<StatusLine>;
  public statuslinesPartialUpdate(
    body: StatusLine,
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<StatusLine>>;
  public statuslinesPartialUpdate(
    body: StatusLine,
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<StatusLine>>;
  public statuslinesPartialUpdate(
    body: StatusLine,
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling statuslinesPartialUpdate.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling statuslinesPartialUpdate.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<StatusLine>(
      'patch',
      `${this.basePath}/statuslines/${encodeURIComponent(String(id))}/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * StatusLine model viewset.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public statuslinesRead(id: string, observe?: 'body', reportProgress?: boolean): Observable<StatusLine>;
  public statuslinesRead(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<StatusLine>>;
  public statuslinesRead(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatusLine>>;
  public statuslinesRead(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling statuslinesRead.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<StatusLine>(
      'get',
      `${this.basePath}/statuslines/${encodeURIComponent(String(id))}/`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   * StatusLine model viewset.
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public statuslinesUpdate(
    body: StatusLine,
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<StatusLine>;
  public statuslinesUpdate(
    body: StatusLine,
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<StatusLine>>;
  public statuslinesUpdate(
    body: StatusLine,
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<StatusLine>>;
  public statuslinesUpdate(
    body: StatusLine,
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling statuslinesUpdate.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling statuslinesUpdate.');
    }

    let headers = this.defaultHeaders;

    // authentication (Basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password),
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<StatusLine>(
      'put',
      `${this.basePath}/statuslines/${encodeURIComponent(String(id))}/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
