import { DocumentSerializerNested, FolderMode } from 'swagger-client';

export const MAX_PAGE_SIZE = 1000000;
export const TOOLTIP_Z_INDEX_10000 = '10000';
export const numberOfFetchRuleConcurrentRequests = 4;
export const allDocumentTypes = Object.values(DocumentSerializerNested.TypeEnum);
export const nbrOfModes = Object.values(FolderMode.ModeEnum).length;
export const DRAWING_STROKE_STYLE = '#92d5a6';
export const PREDICTION_STROKE_STYLE = '#fd656c';
export const LINE_WIDTH = 2;
export const GRAB_CURSOR = 'grab';
export const PEN_CURSOR = 'url(assets/img/pen-solid.svg), auto';
export const RESIZE_DISTANCE = 5;
export const predictionColorPool = ['#baade3', '#ff8a90', '#82ccd9', '#feb76a', '#bcbcbc', '#eabed1', '#eabed1'];
