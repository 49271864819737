/**
 * Snippets API
 * Here is the API DNA
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FolderValidation {
  error: FolderValidation.ErrorEnum;
  type: FolderValidation.TypeEnum;
}
export namespace FolderValidation {
  export type ErrorEnum = 'UNIQUE';
  export const ErrorEnum = {
    UNIQUE: 'UNIQUE' as ErrorEnum,
  };
  export type TypeEnum =
    | 'COMP'
    | 'ACTE'
    | 'TITRE'
    | 'EHYP'
    | 'CAD'
    | 'CAD-REDUIT'
    | 'RU'
    | 'PEB'
    | 'DECOM'
    | 'CI'
    | 'REG'
    | 'ELEC'
    | 'BDES'
    | 'MAIN'
    | 'GARAN'
    | 'VENTE'
    | 'DEPOT'
    | 'BAIL'
    | 'CERTI'
    | 'OVAM'
    | 'CRED'
    | 'ACTE_CRED'
    | 'EH_COMPL'
    | 'EH_DEMANDE'
    | 'OTHER'
    | 'IBGE'
    | 'ARTICLE90'
    | 'COMP_SIGNE'
    | 'ACTE_MAIN'
    | 'OFFRE'
    | 'MAP';
  export const TypeEnum = {
    COMP: 'COMP' as TypeEnum,
    ACTE: 'ACTE' as TypeEnum,
    TITRE: 'TITRE' as TypeEnum,
    EHYP: 'EHYP' as TypeEnum,
    CAD: 'CAD' as TypeEnum,
    CADREDUIT: 'CAD-REDUIT' as TypeEnum,
    RU: 'RU' as TypeEnum,
    PEB: 'PEB' as TypeEnum,
    DECOM: 'DECOM' as TypeEnum,
    CI: 'CI' as TypeEnum,
    REG: 'REG' as TypeEnum,
    ELEC: 'ELEC' as TypeEnum,
    BDES: 'BDES' as TypeEnum,
    MAIN: 'MAIN' as TypeEnum,
    GARAN: 'GARAN' as TypeEnum,
    VENTE: 'VENTE' as TypeEnum,
    DEPOT: 'DEPOT' as TypeEnum,
    BAIL: 'BAIL' as TypeEnum,
    CERTI: 'CERTI' as TypeEnum,
    OVAM: 'OVAM' as TypeEnum,
    CRED: 'CRED' as TypeEnum,
    ACTECRED: 'ACTE_CRED' as TypeEnum,
    EHCOMPL: 'EH_COMPL' as TypeEnum,
    EHDEMANDE: 'EH_DEMANDE' as TypeEnum,
    OTHER: 'OTHER' as TypeEnum,
    IBGE: 'IBGE' as TypeEnum,
    ARTICLE90: 'ARTICLE90' as TypeEnum,
    COMPSIGNE: 'COMP_SIGNE' as TypeEnum,
    ACTEMAIN: 'ACTE_MAIN' as TypeEnum,
    OFFRE: 'OFFRE' as TypeEnum,
    MAP: 'MAP' as TypeEnum,
  };
}
