import { Injectable, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject, takeUntil } from 'rxjs';

/**
 * Abstract class to avoid you to define all destroy logic. Usage:
 *
 * @Component()
 * class MyComponent extends Destroyable {
 *   constructor() {
 *     super();
 *     interval(1000).pipe(this.takeUntilDestroy()).subscribe()
 *   }
 * }
 */
@Injectable()
export abstract class Destroyable implements OnDestroy {
  public readonly destroy$: Subject<void> = new Subject();

  public takeUntilDestroy<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil<T>(this.destroy$);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
