import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements OnInit {
  accepted = false;

  ngOnInit() {
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    if (cookieAccepted) {
      this.accepted = true;
    }
  }

  accept() {
    localStorage.setItem('cookieAccepted', 'true');
    this.accepted = true;
  }
}
