import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService, UserDto } from 'swagger-client';

import { User } from '../models';

/*
 * This API service contains all unauthenticated call to backend.
 * To add authenticated calls, go in API service of the auth module.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private apiAuthService: AuthService) {}

  public me(): Observable<User> {
    return this.apiAuthService.authCurrentList().pipe(
      map((user) => this.userDtoToUser(user)),
      catchError((err) => throwError(() => err))
    );
  }

  public logout(): Observable<void> {
    return this.apiAuthService.authLogoutCreate();
  }

  public userDtoToUser(user: UserDto): User {
    if (user.id === undefined) {
      throw new Error('User is undefined');
    }
    const { ...otherProperties } = user;
    return {
      ...otherProperties,
    };
  }
  public userToUserDto(user: User): UserDto {
    const { ...otherProperties } = user;
    return {
      ...otherProperties,
    };
  }
}
