import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-environment-banner',
  templateUrl: './environment-banner.component.html',
  styleUrls: ['./environment-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentBannerComponent {
  @Input() showBanner$!: Observable<boolean>;
  @Input() showBounce = false;

  public readonly envName = environment.envName;
}
